.AOSImageSwiper {
  position: relative;
  width: 90%;
  height: 90%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, .5);
  padding: 36px 16px 16px;


  & .swiper {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;

    & img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
      margin: 0 auto;
    }
  }

  & .swiper-slide {
    background-size: cover;
    background-position: center;
  }

  & > button {
    position: absolute;
    top: 4px;
    right: 4px;
  }

  & .mySwiper1 {
    height: 80%;
    width: 100%;

  }

  & .mySwiper2 {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0;

    & .swiper-slide {
      width: 25%;
      height: 100%;
      opacity: 0.4;
    }

    & .swiper-slide-thumb-active {
      opacity: 1;
    }

    & img {
      cursor: pointer;
    }

  }
}
