.groupItem {
  padding: 8px 0;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);

  &--title {
    padding: 8px 16px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    font-weight: 400;

    &::-webkit-details-marker,
    &::marker {
      font-size: 16px;
    }
  }

  &--count {
    margin-left: 8px;
    color: rgba(0, 0, 0, .87);
    font-size: 12px;
    font-weight: 400;
  }
}
