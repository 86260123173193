.categoryItem {
  &--title {
    cursor: pointer;
    padding: 4px 0;
    color: #2196F3;
    font-size: 24px;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: center;

    &::-webkit-details-marker,
    &::marker {
      display: none;
      content: "";
    }
  }

  &--count {
    margin-left: 8px;
    color: rgba(0, 0, 0, .87);
    font-size: 12px;
    font-weight: 400;
  }

  &--content {
    background-color: #fff;
  }
}