.labelItem {
  padding: 8px 16px 8px 40px;

  &--title {
    display: flex;
    align-items: center;
    padding: 4px 0 8px;
    cursor: pointer;
    color: rgba(0, 0, 0, .6);
    font-size: 16px;
    font-weight: 400;
    border-bottom: 1px solid rgba(0, 0, 0, .12);

    &::-webkit-details-marker,
    &::marker {
      display: none;
      content: "";
    }
  }

  &--content {
    padding: 16px 16px 0 32px;

    &--infoTitle {
      font-size: 12px;
      font-weight: 400;
      color: rgba(0, 0, 0, .6);
      text-transform: uppercase;
    }

    &--infoContent {
      margin-left: 8px;
      font-size: 12px;
      font-weight: 400;
      color: rgba(0, 0, 0, .6);
    }
  }
}