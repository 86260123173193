@import 'presets/fonts';
@import 'presets/reset';
@import 'presets/consts';

html {
  scroll-behavior: smooth;
}

body {
  background: url('../assets/images/mainBG.jpg') no-repeat center top;
  background-size: 100% 326px;

  @media screen and (max-width: $lg) {
    background-size: auto;
  }
}

.appLayer {
  height: 100vh;
}

.quill {
  & > div {
    border-radius: 4px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

a {
  text-decoration: none;
}

blockquote {
  border-left: 4px solid #ccc;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 16px;
}